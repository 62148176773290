// @flow
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {colors, mediaQueries} from '../theme';
import dimensions from '../theme/dimensions';
import {ChildrenType} from '../types';

const GridSectionDefault = styled.section`
  position: relative;
  box-sizing: border-box;
`;

const GridSectionExpanded = styled(GridSectionDefault)`
  background-color: ${(props) =>
    props.softBackground ? colors.offWhite : '#fff'};
  margin-top: ${dimensions.spacing.small};
  ${mediaQueries.MIN_MD} {
    margin-top: ${dimensions.spacing.vertical.medium};
  }
  ${mediaQueries.MIN_XXL} {
    margin-top: ${dimensions.spacing.vertical.large};
  }
`;

class Section extends React.Component {
  render() {
    const {theme, children} = this.props;

    if (theme === 'expanded') {
      return (
        <GridSectionExpanded {...this.props}>{children}</GridSectionExpanded>
      );
    }

    return <GridSectionDefault {...this.props}>{children}</GridSectionDefault>;
  }
}

Section.defaultProps = {
  theme: 'default',
};

Section.propTypes = {
  theme: PropTypes.oneOf(['default', 'expanded']),
  children: ChildrenType.isRequired,
};

export default Section;
