// @flow

// Before working with this component, please familiarise yourself with the concept of "srcset" and "sizes" for image selection: https://ericportis.com/posts/2014/srcset-sizes/

import {ImgixGatsbyImage} from '@imgix/gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {Blurhash} from 'react-blurhash';
import styled from 'styled-components';

import dimensions from '../theme/dimensions';
import {ImageSourceType, ImageModifiersType} from '../types';

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;

  .imageContainer {
    position: relative;
    margin: 0;
    display: block;

    overflow: hidden;

    // We apply a subtle default border radius to all images. This can be overriden by passing a prop, including a "0px" value
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : dimensions.borderRadius};

    .blurhash {
      position: absolute !important;
      max-width: 100%;
      max-height: 100%;
    }

    img {
      margin: 0;
      padding: 0;
      display: block;
    }
  }
`;

const Caption = styled.span`
  width: 100%;
  max-width: 680px;
  font-size: 0.6em;
  line-height: 1.5;
  color: #888;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 1rem auto 0 0;
`;

class Image extends React.Component {
  render() {
    const {className, imageSource, imageModifiers} = this.props;

    let computed = {
      height: null,
      width: null,
      applyCrop: null,
      aspectRatio: null,
      alt: '', // Gatsby Image requires a string as the default, not a null
    };

    // LOGIC FOR CROPPING
    // TODO: Consider moving this to a function and then just returning the object? This would allow us to test more extensively

    // All images require us to manually set a width property. Otherwise, the full size Imgix image will be included, which is far too big
    if (imageModifiers && imageModifiers.width) {
      // These can be set for all contexts, defaulting to null
      computed.height = imageModifiers.height;
      computed.width = imageModifiers.width;
      computed.applyCrop = true; // We need to enable cropping mode in Imgix

      // If the height is not supplied, we will need to calculate this based on the Imgix source image dimensions
      if (!imageModifiers.height) {
        // If the DatoCMS source image (imageSource) height=1000 and width=2000
        // Then the h/w aspect ratio = 1000/2000 = 0.5
        // If imageModifiers.width=1000, then we can calculate the necessary output height as 1000*0.5=500
        computed.height =
          (imageSource.height / imageSource.width) * imageModifiers.width;
      }
    } else {
      // This should be prevented by our types, but if you see images disappearing, uncomment these lines to more easily debug what's going on
      // console.log(
      //   'We could not find an imageModifiers.width or imageModifiers.height',
      // );
      // console.log(imageSource);
      // console.log(imageModifiers);
      return null;
    }

    // Allows overriding of the alt value on a per image basis, even if has been configured in Dato
    // This is useful when we want to configure it for a specific page
    if (imageModifiers && imageModifiers.alt) {
      computed.alt = imageModifiers.alt;
    } else if (imageSource.alt) {
      computed.alt = imageSource.alt;
    }

    return (
      <Figure
        className={className} // Required in order for any Styled Component parent styles to target this element
        borderRadius={imageModifiers && imageModifiers.borderRadius}>
        <div className="imageContainer">
          {imageSource.blurhash && !imageModifiers.hideBlurhash && (
            // We use the dimensions of the original image for the blurhash, so that it scales perfectly underneath
            // When the real image animates in, it will conceal the blurhash itself
            <Blurhash
              className="blurhash"
              hash={imageSource.blurhash}
              width={computed.width}
              height={computed.height}
              punch={1}
            />
          )}
          <ImgixGatsbyImage
            src={imageSource.url}
            layout="constrained"
            width={computed.width}
            height={computed.height}
            aspectRatio={computed.aspectRatio}
            maxWidth={computed.width * 2}
            placeholder={'none'} // Tells Gatsby Image not to generate any sort of placeholder. No relation to our custom blurhash
            imgixParams={{
              fm: 'webp', // The image URL will still contain the source file extension (e.g. .png) but will be delivered as webp
              auto: 'format', // Imgix recommended fallback to support older versions of Safari https://docs.imgix.com/apis/rendering/format/fm#webp
              fit: computed.applyCrop ? 'crop' : 'max', // The "max" mode will resize the image downwards to fit, but will not crop the bounds
              crop: imageModifiers && imageModifiers.crop,
              'fp-x':
                imageModifiers &&
                imageModifiers.crop === 'focalpoint' &&
                imageSource.focalPoint.x,
              'fp-y':
                imageModifiers &&
                imageModifiers.crop === 'focalpoint' &&
                imageSource.focalPoint.y,
              'max-w': computed.width * 2, // This constrains the Imgix image itself, relative to our assumed render width
              'max-h': computed.height * 2, // This constrains the Imgix image itself, relative to our assumed render height
            }}
            alt={computed.alt}
          />
        </div>
        {imageModifiers && imageModifiers.caption && (
          <Caption className="caption">{imageModifiers.caption}</Caption>
        )}
      </Figure>
    );
  }
}

Image.propTypes = {
  className: PropTypes.string,
  imageSource: ImageSourceType,
  imageModifiers: ImageModifiersType,
};

export default Image;
